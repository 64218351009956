<template>
  <v-dialog
    :content-class="dialogClasses"
    :hide-overlay="isMinimized"
    persistent
    :value="isVisible"
    width="25vw"
  >
    <v-card>
			<v-card-title class="w-dialog__upload-progress__title primary white--text">
				<v-layout align-center fluid row white--text>
          <v-flex shrink>
            <v-icon class="mr-2" small >fas fa-upload</v-icon>
          </v-flex>
          <v-flex v-if="isMinimized" grow mr-1>
            <v-layout align-center row>
              <v-progress-linear 
                class="upload-progress__linear ml-2"
                color="white"
                :value="overallProgressPercentage"
              />
              <span class="upload-progress__percentage ml-2">{{ overallProgressPercentage }}%</span>
            </v-layout>
          </v-flex>
					<v-flex 
            align-right
            :grow="!isMinimized"
            headline
            :ml-1="isMinimized"
            :shrink="isMinimized"
            text-truncate
          >
            {{ displayTitle }}
          </v-flex>
          <v-flex shrink>
            <v-btn color="primary" icon :small="isMinimized" @click.stop="toggleMinimize()">
              <v-icon>{{ isMinimized ? 'fas fa-chevron-down' : 'fas fa-chevron-up' }}</v-icon>
            </v-btn>
          </v-flex>
				</v-layout>
			</v-card-title>
			<v-card-text v-if="!isMinimized">
        <v-layout column fill-height>
          <v-flex v-for="(listItem, index) in listItems" :key="index" shrink>
            <v-subheader 
              v-if="listItem.type === 'subheader'"
              :class="index !== 0 ? 'mt-2' : ''"
              style="height:24px"
            >
              {{listItem.text}}
            </v-subheader>
            <UploadFileItem
              v-else-if="listItem.type !== 'folder'"
              :failed="listItem.failed"
              :fullpath="listItem.fullpath"
              :name="listItem.name"
              :progression="listItem.progression"
              :show-checkmark="listItem.progression === 100"
              show-progress
              :size="listItem.size"
              :type="listItem.type"
              @hide="removeUpload(listItem)"
            />
            <template v-else-if="listItem.type === 'folder'">
              <v-layout column class="px-2 py-1">
                <v-layout row align-center class="upload-item__info">
                  <UploadHeaderInfo
                    :name="listItem.name"
                    :total="listItem.stats.total"
                  />

                  <UploadProgressStats
                    :completed="listItem.stats.completed"
                    :total="listItem.stats.total"
                    :uploaded-size="listItem.stats.uploadedSize"
                    :total-size="listItem.stats.totalSize"
                  />
                </v-layout>

                <UploadProgressBarInfo :value="getBatchOverallProgress(listItem)" />

                <!-- Add file-level details for directory uploads -->
                <UploadFileDetails
                  v-if="listItem.items.length > 0"
                  :batch-id="listItem.id"
                  :files="listItem.items"
                />
              </v-layout>
            </template>
          </v-flex>
        </v-layout>
			</v-card-text>
		</v-card>
  </v-dialog>
</template>

<script>
import AppModuleGuard from '@/mixins/ModulesGuards/AppModuleGuard'

export default {
  name: 'UploadProgress',
  components: {
    UploadFileDetails: () => ({
      component: import('@/components/Documents/Upload/UploadFileDetails')
    }),
    UploadFileItem: () => ({
      component: import('@/components/Documents/Upload/UploadFileItem')
    }),
    UploadHeaderInfo: () => ({
      component: import('@/components/Documents/Upload/UploadHeaderInfo')
    }),
    UploadProgressBarInfo: () => ({
      component: import('@/components/Documents/Upload/UploadProgressBarInfo')
    }),
    UploadProgressStats: () => ({
      component: import('@/components/Documents/Upload/UploadProgressStats')
    })
  },
  mixins: [AppModuleGuard],
  data: function() {
    return {
      isMinimized: false,
      listItems: [],
      totalCompleted: 0,
      totalFiles: 0
    }
  },
  computed: {
    dialogClasses: function () {
      const classes = ['w-dialog__upload-progress']
      
      if (this.isMinimized) {
        classes.push('w-dialog__upload-progress--minimized')
      }

      return classes.join(' ')
    },
    displayTitle: function() {
      const nbOfUploads = (this.totalFiles - this.totalCompleted)
      return this.$tc('documents.upload.progress_title', nbOfUploads, { count: nbOfUploads })
    },
    isVisible: function () {
      return this.listItems.length > 0
    },
    overallProgressPercentage: function () {
      let result = 0

      if (this.totalFiles > 0) {
        result = Math.round((this.totalCompleted / this.totalFiles) * 100)
      }

      return result
    }
  },
  watch: {
    isVisible: function (isVisible) {
      if (!isVisible) {
        this.resetComponent()
      }
    }
  },
  mounted: function() {
    this.isMinimized = localStorage.getItem('uploadProgress_preference') === 'minimized'
  },
  methods: {
    getAppEventsActionsMapping: function () {
      return [
        { event: this.appEvents.UPDATE_UPLOAD_PROGRESS, action: this.onAddUploadProgress },
        { event: this.appEvents.COMPLETE_UPLOAD_PROGRESS, action: this.onCompleteUploadProgress },
        { event: this.appEvents.UPLOAD_STARTED, action: this.onUploadStarted },
        { event: this.appEvents.CANCEL_ALL_UPLOADS, action: this.onCancelUpload },
        { event: this.appEvents.UPLOAD_FAILED, action: this.onUploadFailed }
      ]
    },
    addUploads: function (vendorId, uploads, addToTotal = true) {
      const index = this.findIndexOfTheLastVendorItem(vendorId)
      this.listItems.splice(index + 1, 0, ...uploads)
      if (addToTotal) {
        this.totalFiles += uploads.length
      }
    },
    createFileUploadItem: function (vendorId, file, parent = null) {
      let result = {
        failed: false,
        id: file.id,
        name: file.name,
        progression: 0,
        type: file?.isDirectory ? 'folder' : file.type,
        size: file.size ?? 0,
        vendor_id: vendorId
      }

      if (parent) {
        result.fullpath = parent.fullpath + '/' + file.name
      }

      return result
    },
    createFileUploadItems: function (vendorId, files, parent = null) {
      let result = []
      
      files.forEach(file => {
        const fileItem = this.createFileUploadItem(vendorId, file, parent)
        result.push(fileItem)
        if (file?.isDirectory) {
          result.push(...this.createFileUploadItems(vendorId, file.children, fileItem))
        }
      })

      return result
    },
    createFolderUploadItem: function (vendorId, folder, parent = null) {
      const folderUpload = {
        failed: false,
        id: folder.id,
        items: [],
        name: folder.name,
        type: 'folder',
        stats: {
          completed: 0,
          total: 0,
          totalSize: 0,
          uploadedSize: 0
        },
        vendor_id: vendorId
      }

      if (parent) {
        folderUpload.fullpath = parent.fullpath + '/' + folderUpload.name
      }

      folder.children
        .forEach(child => {
          let nodes = []

          if (child?.isDirectory || child?.size > 0) {
            nodes.push(this.createFileUploadItem(vendorId, child, folderUpload))
          } 
          if (child?.isDirectory) {
            nodes.push(...this.createFileUploadItems(vendorId, child.children, nodes[0]))
          }

          let totalSizeToAdd = 0
          nodes.forEach(node => {
            node.batch_id = folder.id
            if (node.size) {
              totalSizeToAdd += node.size
            }
          })
          
          folderUpload.stats.total += nodes.length
          folderUpload.stats.totalSize += totalSizeToAdd

          folderUpload.items.push(...nodes)
        })

      this.totalFiles += folderUpload.stats.total

      return folderUpload
    },
    createVendorSubheader: function (vendor) {
      return {
        text: vendor.company,
        type: 'subheader',
        vendor_id: vendor.id
      }
    },
    findIndexOfTheLastVendorItem: function (vendorId) {
      let index = 0

      if (vendorId) {
        index = this.listItems.findLastIndex(listItem => {
          return listItem.vendor_id === vendorId
        })

        if (index === -1) {
          index = this.listItems.length - 1
        } else {
          index
        }
      }

      return index
    },
    findUpload: function (uploadId) {
      let upload = undefined

      for (let i = 0; i < this.listItems.length; i++) {
        const listItem = this.listItems[i]

        if (listItem.id === uploadId) {
          upload = listItem
        } else if (listItem.type === 'folder') {
          upload = listItem.items
            .find(item => item.id === uploadId)
        }

        if (upload) {
          break
        }
      }

      return upload
    },
    findUploadIndex: function (uploadId) {
      let index = -1

      if (uploadId) {
        index = this.listItems.findIndex(listItem => listItem.id === uploadId)
      }

      return index
    },
    getBatchOverallProgress: function (batch) {
      let result = 0

      if (batch?.stats && batch.stats.completed > 0) {
        result = Math.round((batch.stats.completed / batch.stats.total) * 100)
      }

      return result
    },
    onAddUploadProgress: function (uploadInfo) {
      if (uploadInfo.id) {
        let oldProgress = 0
        const upload = this.findUpload(uploadInfo.id)
        if (upload) {
          oldProgress = upload.progression
          upload.failed = false
          upload.progression = uploadInfo.progression
        }
        if (upload?.batch_id) {
          const parentBatch = this.findUpload(upload.batch_id)
          const sizeDiff = uploadInfo.size ? (uploadInfo.size * ((upload.progression - oldProgress) / 100)) : 0;
          parentBatch.stats.uploadedSize = Math.round(parentBatch.stats.uploadedSize + sizeDiff);
        }
      }
    },
    // eslint-disable-next-line sonarjs/cognitive-complexity
    onCancelUpload: function (uploadId) {
      if (!uploadId) {
        return
      }
      const upload = this.findUpload(uploadId)
      if (!upload) {
        return
      }

      if (!upload.batch_id) {
        let subTotal = 1
        if (upload.items) {
          subTotal = upload.items.length + 1
        }
        this.totalFiles -= subTotal
        this.removeUpload(upload)
      } else {
        const parentUpload = this.findUpload(upload.batch_id)
        const uploadsToRemove = [upload]
        if (upload.type === 'folder') {
          const searchedFullpath = `${upload.fullpath}/`
          parentUpload.items.forEach(item => {
            if (item.fullpath
              && item.fullpath.startsWith(searchedFullpath)
            ) {
              uploadsToRemove.push(item)
            }
          })
        }

        parentUpload.stats.total -= uploadsToRemove.length
        parentUpload.stats.totalSize -= uploadsToRemove.reduce(
          (accumulator, upload) => accumulator + (upload.size ?? 0),
          0
        )
        this.totalFiles -= uploadsToRemove.length

        uploadsToRemove.forEach(upload => {
          this.removeUpload(upload)
        })
      }
    },
    onCompleteUploadProgress: function (uploadId) {
      const upload = this.findUpload(uploadId)

      if (upload?.batch_id) {
        const parentBatch = this.findUpload(upload.batch_id)
        parentBatch.stats.completed++
      }
      if (upload && !upload.hasOwnProperty('items')) {
        this.totalCompleted++
        setTimeout(function () {
          this.removeUpload(upload)
        }.bind(this), 2000)
      }
    },
    onUploadFailed: function(uploadId) {
      if (uploadId) {
        const upload = this.findUpload(uploadId)
        if (upload) {
          upload.failed = true
          upload.progression = 0
          this.totalCompleted++
        }
        if (upload.batch_id) {
          const parentUpload = this.findUpload(upload.batch_id)
          parentUpload.stats.totalCompleted++
          this.removeUpload(upload)
          delete upload.batch_id
          this.addUploads(upload.vendor_id, [upload], false)
        }
      }
    },
    onUploadStarted: function(uploadInfo) {
      if (!this.vendorHasUploadInProgress(uploadInfo.vendor?.id)) {
        this.listItems.push(
          this.createVendorSubheader(uploadInfo.vendor)
        )
      }

      const parent = uploadInfo.folder
      const vendorId = uploadInfo.vendor?.id
      let uploads = []
      uploadInfo.items
        .forEach(uploadItem => {
          if (uploadItem?.isDirectory) {
            uploads.push(this.createFolderUploadItem(vendorId, uploadItem, parent))
          } else if (uploadItem.size > 0) {
            uploads.push(this.createFileUploadItem(vendorId, uploadItem, parent))
          }
        })

      if (uploads.length > 0) {
        this.addUploads(uploadInfo.vendor?.id, uploads)
      }
    },
    toggleMinimize: function() {
      this.isMinimized = !this.isMinimized
      localStorage.setItem('uploadProgress_preference', this.isMinimized ? 'minimized' : 'maximized')
    },
    removeUpload: function (upload) {
      if (upload.batch_id) {
        const batch = this.findUpload(upload.batch_id)
        const uploadIndex = batch.items.findIndex(item => item.id === upload.id)
        if (uploadIndex >= 0 && batch.items.length === 1) {
          this.removeUpload(batch)
        } else if (uploadIndex >= 0) {
          batch.items.splice(uploadIndex, 1)
        }
      } else {
        const uploadIndex = this.findUploadIndex(upload.id)
        this.listItems.splice(uploadIndex, 1)

        const vendorId = upload.vendor_id
        if (vendorId
          && this.listItems.filter(listItem => {
            return listItem.vendor_id === vendorId
              && listItem.type !== 'subheader'
          }).length === 0
        ) {
          this.listItems.splice(uploadIndex - 1, 1)
        }
      }
    },
    resetComponent: function () {
      this.totalCompleted = 0
      this.totalFiles = 0
    },
    vendorHasUploadInProgress: function (vendorId) {
      return !!vendorId && this.listItems.some(listItem => {
        return vendorId === listItem.vendor_id
      })
    }
  }
}
</script>

<style scoped>
:deep(.w-dialog__upload-progress) {
  max-width: 100%;
  min-width: 350px;
  transition: all 0.3s ease;
  width: 25vw;
}
:deep(.w-dialog__upload-progress--minimized) {
  margin: 0;
  position: fixed;
  top: 0;
}
:deep(.w-dialog__upload-progress__title) {
  position: sticky;
  top: 0;
  z-index: 4;
}
:deep(.w-dialog__upload-progress--minimized .w-dialog__upload-progress__title) {
  padding-bottom: 8px;
  padding-top: 8px;
}
:deep(.w-dialog__upload-progress--minimized .headline) {
  font-size: 14px !important;
}

@media (max-width: 600px) {
  :deep(.w-dialog__upload-progress) {
    max-width: 100%;
    min-width: 100%;
  }
}
</style>

